import PortfolioContainer from "./PortfolioContainer/PortfolioContainer";
import "./App.css";
import Footer from "./PortfolioContainer/Footer/Footer";

function App() {
  return (
    <div>
      <div classNameName="App">
        <PortfolioContainer />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
