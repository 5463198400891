import React from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";

import "./AboutMe.css";

export default function AboutMe(props) {
  // Smooth effect
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  // Subscribe the observables
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTANTS = {
    description:
      "Senior developer with over 7+ years of industry experience, including over 2+ years spent in the React \
      ecosystem. competent in building application interface code using Javascript and React workflows, as well \
      as in converting designs wireframes into high-quality code. I am familiar with Javascript, Media queries, \
      HTML5, CSS3, React testing library, GIT, Agile, and accessibility REST API, Browser debugging, \
      Problem-solving abilities, and the ability to work well in a team while communicating effectively.",
    highlights: {
      bullets: [
        "Fullstack web development",
        "Interactive Front end as per the design",
        "React",
        "Redux for state management",
        "Building REST API",
        "Managing database",
      ],
      heading: "Here are a few Highlights:",
    },
  };

  const renderHighlights = () => {
    return SCREEN_CONSTANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={""}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };
  return (
    <div>
      <div className="about-me-container screen-container" id={props.id || ""}>
        <div className="about-me-parent">
          <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
          <div className="about-me-card">
            <div className="about-me-profile"></div>
            <div className="about-me-details">
              <span className="about-me-description">
                {SCREEN_CONSTANTS.description}
              </span>
              <div className="about-me-highlights">
                <div className="highlight-heading">
                  <span>{SCREEN_CONSTANTS.highlights.heading}</span>
                </div>
                {renderHighlights()}
              </div>
              <div className="about-me-options">
                <button
                  className="btn primary-btn"
                  onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                >
                  Hire Me
                </button>
                <a href="sarfaraz-resume.pdf" download="sarfaraz-resume.pdf">
                  <button className="btn highlighted-btn">Get Resume</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
