import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Frontend", logoSrc: "work-history.svg" },
    { label: "Backend", logoSrc: "programming-skills.svg" },
    { label: " Database", logoSrc: "education.svg" },
    { label: "Cloud Computing", logoSrc: "interests.svg" },
    { label: "DevOps", logoSrc: "projects.svg" },
  ];

  //here we have
  const frontendDetails = [
    { skill: "Javascript", ratingPercentage: 85 },
    { skill: "React.js", ratingPercentage: 85 },
    { skill: "Typescript", ratingPercentage: 65 },
    { skill: "HTML5 / CSS3", ratingPercentage: 80 },
    { skill: "React Testing Library", ratingPercentage: 65 },
    { skill: "Accessibility", ratingPercentage: 65 },
  ];

  const backendDetails = [
    { skill: "Express.js", ratingPercentage: 65 },
    { skill: "Node", ratingPercentage: 65 },
    { skill: "MySQL / Mongodb", ratingPercentage: 65 },
  ];

  const projectsDetails = [
    {
      title: "Personal Portfolio Website",
      duration: { fromDate: "2023 ", toDate: "2023" },
      description:
        "A Personal Portfolio website to showcase all my details and projects at one place",
      subHeading: "Technologies Used: Reactjs, Bootstrap",
    },
    {
      title: "Ecommerce Website",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "Online ecommerce website for showcasing and selling products online with payment system ",
      subHeading:
        "Technologies Used: Reactjs, Bootstrap, Redux, Nodejs, Expressjs, MongoDB",
    },
  ];

  const resumeDetails = [
    /* frontend SKILLS */

    <div className="resume-screen-container" key="frontend">
      <ResumeHeading
        heading="JavaScript Framework"
        subHeading="ReactJS: Context API, Redux, Hooks, RTL, Jest"
      />
      <ResumeHeading
        heading="CSS Framework"
        subHeading="Bootstrap, Material UI, Ant Design, Tailwindcss"
      />
      <ResumeHeading heading="HTML, CSS, JavaScript" />
      <ResumeHeading heading="Dev Tools - Debugging" description="" />
      <ResumeHeading
        heading="Third Party Libraries"
        description="animate.style, D3.JS, High charts"
      />
    </div>,
    /* backend SKILLS */
    <div className="resume-screen-container" key="backend">
      <ResumeHeading
        heading="API Development (Microservices)"
        subHeading="NodeJS, ExpressJS, Postman"
      />
      <ResumeHeading heading="Version Control" subHeading="GitHub, GitBash" />
      <ResumeHeading heading="Package Manager" subHeading="npm, yarn, lerna" />
    </div>,

    /* database */
    <div className="resume-screen-container" key="database">
      <ResumeHeading heading="RDBMS" subHeading="Postgresql, MySQL" />
      <ResumeHeading heading="NoSQL" subHeading="MongoDB" />
    </div>,

    <div className="resume-screen-container" key="cloudcomputing">
      <h4>Amazon Web Services</h4>
      <ResumeHeading heading="AWS Route53" subHeading="" />
      <ResumeHeading heading="AWS IAM" subHeading="" />
      <ResumeHeading heading="AWS Cloud9" subHeading="" />
      <ResumeHeading heading="AWS EC2" subHeading="" />
      <ResumeHeading heading="AWS EBS" subHeading="" />
      <ResumeHeading heading="AWS RDS" subHeading="" />
      <ResumeHeading heading="AWS Elastic Beanstalk" subHeading="" />
      <ResumeHeading heading="AWS S3" subHeading="" />
      <ResumeHeading heading="AWS Snapshot" subHeading="" />
      <ResumeHeading heading="AWS CLI" subHeading="" />
      <ResumeHeading heading="AWS Polly" subHeading="" />
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="devops">
      <ResumeHeading heading="Tools" subHeading="Putty, Puttygen" />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div className="resume-container screen-container" id={props.id || ""}>
      <div className="resume-content">
        <ScreenHeading
          title={"Technical Skills"}
          subHeading={"My formal Skills Details"}
        />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
