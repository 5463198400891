import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import "./Profile.css";

import ScreenHeading from "../../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../../utilities/ScrollService";
import Animations from "../../../utilities/Animations";

export default function Profile(props) {
  // Smooth effect
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  // Subscribe the observables
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [typeEffect] = useTypewriter({
    words: [
      "Enthusiastic Dev 🔴",
      "Fullstack Developer 💻",
      "MERN Stack Dev 😎",
      "React Developer 👨‍💻",
    ],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="profile-details-name">
            <h3 className="primary-text">
              {" "}
              welcome to my <span className="highlighted-text">portfolio</span>
            </h3>
          </div>

          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              {/*  <h1>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#2B3499",
                    marginLeft: "5px",
                  }}
                >
                  {typeEffect}
                </span>
              </h1> */}
              <span className="profile-role-tagline">
                Knack of building applications with front and backend
                operations.
              </span>
            </span>
          </div>

          <div className="profile-options">
            <button
              className="btn primary-btn"
              // onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              Hire Me
            </button>
            <a href="sarfaraz-resume.pdf" download="sarfaraz-resume.pdf">
              <button className="btn highlighted-btn">Get Resume</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
