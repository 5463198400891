import React, { useState } from "react";
import imgBack from "../../../src/images/mailz.jpeg";
import load1 from "../../../src/images/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import { useTypewriter } from "react-simple-typewriter";
import "./ContactMe.css";
import axios from "axios";
import { toast } from "react-toastify";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [typeEffect] = useTypewriter({
    words: ["Get in touch 🤝 "],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let data = {
        name,
        email,
        message,
      };
      setBool(true);
      const res = await axios.post(`/contact`, data);
      if (name.length === 0 || email.length === 0 || message.length === 0) {
        setBanner(res.data.msg);
        toast.error(res.data.msg);
        setBool(false);
      } else if (res.status === 200) {
        setBanner(res.data.msg);
        toast.success(res.data.msg);
        setBool(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="main-container" id={props.id || ""}>
      {/* <ScreenHeading title={"Contact Me"} subHeading={"Lets keep in touch"} /> */}
      <div className="central-form">
        <div className="col">
          <h2 className="title">
            <span
              style={{
                fontWeight: "bold",
                color: "orange",
                marginLeft: "5px",
              }}
            >
              {typeEffect}
            </span>
          </h2>
          <div>
            <a href="https://www.linkedin.com/in/hsarfaraz">
              <i className="fa fa-2x fa-brands fa-linkedin"></i>
            </a>
            <a href="https://www.github.com/in/hsarfaraz">
              <i className="fa fa-2x fa-brands fa-github"></i>
            </a>
          </div>
          <div className="back-form">
            <div className="img-back">
              <h4>Send your email here!</h4>
              <img src={imgBack} alt="image not found" />
            </div>
            <form onSubmit={submitForm}>
              <p>{banner}</p>
              <label htmlFor="name">Name</label>
              <input type="text" onChange={handleName} value={name} />

              <label htmlFor="email">Email</label>
              <input type="email" onChange={handleEmail} value={email} />

              <label htmlFor="message">Message</label>
              <input type="text" onChange={handleMessage} value={message} />

              <div className="send-btn">
                <button type="submit" disabled>
                  Send{" "}
                  <i className="fa fa-paper-plane">
                    {bool ? (
                      <b className="load">
                        <img src={load1} alt="image not responding"></img>
                      </b>
                    ) : (
                      ""
                    )}
                  </i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
